var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-4 p-st",class:{
    'bg-light': _vm.nightMode,
    'bg-dark2': _vm.nightMode,
    'text-light': !_vm.nightMode,
  }},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"text-center",attrs:{"data-aos":"fade","data-aos-once":"true","data-aos-duration":"1000"}},[_c('span',{staticClass:"title text-center",class:{ pgray: !_vm.nightMode, 'text-light': _vm.nightMode }},[_vm._v("Contact.")])]),_c('hr',{class:{ pgray: !_vm.nightMode, 'bg-secondary': !_vm.nightMode },attrs:{"width":"50%"}}),_c('br'),_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"mb-3",attrs:{"data-aos":"fade-up","data-aos-once":"true","data-aos-duration":"1000"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"pinput",class:{
            pgray: !_vm.nightMode,
            'pgray-dark': _vm.nightMode,
            'text-light': _vm.nightMode,
          },staticStyle:{"transition-delay":"0.2s"},attrs:{"type":"text","name":"user_name","placeholder":"name"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}})]),_c('div',{staticClass:"my-3",attrs:{"data-aos":"fade-up","data-aos-once":"true","data-aos-duration":"1000"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"pinput",class:{
            pgray: !_vm.nightMode,
            'pgray-dark': _vm.nightMode,
            'text-light': _vm.nightMode,
          },staticStyle:{"transition-delay":"0.4s"},attrs:{"type":"email","name":"user_email","placeholder":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}})]),_c('div',{staticClass:"my-3",attrs:{"data-aos":"fade-up","data-aos-once":"true","data-aos-duration":"1000"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],staticClass:"pinput",class:{
            pgray: !_vm.nightMode,
            'pgray-dark': _vm.nightMode,
            'text-light': _vm.nightMode,
          },staticStyle:{"transition-delay":"0.6s"},attrs:{"name":"message","placeholder":"message","rows":"4"},domProps:{"value":(_vm.text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.text=$event.target.value}}})]),_c('button',{staticClass:"mt-1 btn mb-3",attrs:{"data-aos":"fade","data-aos-once":"true","data-aos-duration":"1000","data-aos-offset":"50"},on:{"click":function($event){$event.preventDefault();return _vm.sendEmail.apply(null, arguments)}}},[_vm._v(" Send ")])]),_c('Snackbar',{attrs:{"showSnackbar":_vm.showSnackbar,"snackbarMessage":_vm.snackbarMessage,"snackbarColor":_vm.snackbarColor},on:{"close":_vm.closeSnackbar}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }