let config = {
  emailjs: {
    serviceID: "service_portfolio",
    templateID: "template_i30mdtu",
    userID: "service_portfolio",
    publicKey: "FDnvEBGLe94FHCjYd"
  }
};

export default config;
